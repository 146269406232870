.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input-container label {
  margin-bottom: 4px;
}

input {
  padding: 8px;
  font-size: 16px;
}

.submit-btn {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 480px) {
  .my-form {
    padding: 0 16px;
  }
}

.big-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 16px 24px;
  margin: 8px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.big-button:hover {
  background-color: #0056b3;
}

.big-button img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.div-page3 {
  flex: 1;
  border: 1px solid black;
  width: 70vw;
  padding: 16px;
  margin: 10px 0;
}

.div-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.same-size-div {
  flex: 1;
  border: 1px solid black;
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .div-page3 {
    width: 90vw;
    padding: 10px;
    margin: 5px 0;
  }

  .heading {
    font-size: 18px;
  }

  .div-page3 input,
  .div-page3 p,
  .div-page3 .MuiFormControlLabel-root {
    width: 90%;
    text-align: center;
  }
}
